import React, { ReactElement } from 'react';
import useLocale from 'hooks/useLocale';
import { Readability } from 'core/types';

interface Props {
  scores: Readability;
}

const computeReadabilityLevel = (
  scores: Readability,
): string => {
  if (scores.flesch_reading_ease < 30) {
    return 'very difficult';
  } if (scores.flesch_reading_ease < 50) {
    return 'difficult';
  } if (scores.flesch_reading_ease < 60) {
    return 'fairly difficult';
  } if (scores.flesch_reading_ease < 70) {
    return 'standard';
  } if (scores.flesch_reading_ease < 80) {
    return 'fairly easy';
  } if (scores.flesch_reading_ease < 90) {
    return 'easy';
  }
  return 'very easy';
};

const ReadabilityLevel: React.FC<Props> = ({ scores }): ReactElement => {
  const { t } = useLocale();

  if (!scores) {
    return null;
  }

  const level = computeReadabilityLevel(scores);

  if (level.endsWith('difficult')) {
    return <span className="px-2 text-xs font-semibold tracking-wide text-red-800 uppercase bg-red-200 rounded-full">{t(level)}</span>;
  } if (level.endsWith('easy')) {
    return <span className="px-2 text-xs font-semibold tracking-wide text-green-800 uppercase bg-green-200 rounded-full">{t(level)}</span>;
  }
  return <span className="px-2 text-xs font-semibold tracking-wide text-teal-800 uppercase bg-teal-200 rounded-full">{t(level)}</span>;
};

export default ReadabilityLevel;
