import React, { ReactElement } from 'react';
import Link, { LinkProps } from 'next/link';
import { capitalize, getAvatarURL } from 'core/utils';
import Image from 'components/Image';
import Photo from '../public/images/icons/photo.svg';

interface Props {
  label: string;
  entityId?: string;
  linkClassName?: string;
  className?: string;
  linkProps: LinkProps;
  compact?: boolean;
}

const imageClasses = 'object-cover w-8 h-8 rounded-full shadow-inner inline-block border border-gray-300 dark:border-dark-light mr-1';
const loadingPhoto = <div className={`flex items-center align-center ${imageClasses}`}><Photo className="block w-4 h-4 m-auto text-gray-200 fill-current dark:text-dark-light" /></div>;

const compactImageClasses = 'object-cover w-6 h-6 rounded-full shadow-inner inline-block border border-gray-300 dark:border-dark-light mr-1';
const compactLoadingPhoto = <div className={`flex items-center align-center ${imageClasses}`}><Photo className="block w-3 h-3 m-auto text-gray-200 fill-current dark:text-dark-light" /></div>;

export const ChipLoading: React.FC = (): ReactElement => (
  <a className="flex-shrink-0 mr-1">
    <div className="flex items-center justify-center inline-block w-full h-full px-2 py-1 mr-1 text-sm font-semibold leading-loose text-gray-700 bg-white border border-gray-400 element dark:bg-dark-medium dark:border-dark-light dark:text-dark-top-light">
      {loadingPhoto}
      <span className="w-48 bg-gray-200 rounded-lg dark:bg-dark-light">&nbsp;</span>
    </div>
  </a>

);

const AvatarImage: React.FunctionComponent<
{
  source: string;
  label: string;
  compact: boolean;
}> = ({
  source, label, compact,
}) => (
  <Image
    source={source}
    imageClasses={compact ? compactImageClasses : imageClasses}
    loader={compact ? compactLoadingPhoto : loadingPhoto}
    unloader={null}
    transform={{
      fit: 'cover',
      we: '',
      width: compact ? 24 : 32,
      height: compact ? 24 : 32,
    }}
    attributes={{ alt: label }}
    lazyLoadOffset={600}
  />
);

const Chip: React.FunctionComponent<Props> = (
  {
    label, entityId, linkClassName, className, linkProps,
  },
): ReactElement => {
  const sharedClassName = `font-semibold text-sm text-gray-700 hover:text-indigo-600 border p-1 border-gray-400 hover:border-indigo-400 bg-white dark:bg-dark-medium dark:border-dark-light dark:text-dark-top-light hover:bg-indigo-100 dark:hover:bg-dark-light dark:hover:text-dark-top-light dark:hover:border-dark-light leading-loose inline-block element ${className}`;

  return (
    <Link href={linkProps.href} as={linkProps.as}>
      <a className={`topic flex-shrink-0 ${linkClassName}`}>
        {entityId ? (
          <div className={`flex items-center justify-center px-2 py-1 ${sharedClassName}`}>
            <AvatarImage
              source={getAvatarURL(entityId, 64)}
              label={label}
              compact={false}
            />
            <span>{capitalize(label)}</span>
          </div>
        ) : (
          <div className={`${sharedClassName} p-1`}>
            <span className="px-2">{capitalize(label)}</span>
          </div>
        )}

      </a>
    </Link>
  );
};

export const CompactChip: React.FunctionComponent<Props> = (
  {
    label, entityId, linkClassName, className, linkProps,
  },
): ReactElement => {
  const sharedClassName = `font-semibold text-xs text-indigo-600 hover:text-indigo-800 dark:text-indigo-300 dark:hover:text-indigo-400 ${className}`;

  return (
    <Link href={linkProps.href} as={linkProps.as}>
      <a className={`topic flex-shrink-0 ${linkClassName}`}>
        {entityId ? (
          <div className={`flex items-center ${sharedClassName}`}>
            <AvatarImage
              source={getAvatarURL(entityId, 48)}
              label={label}
              compact
            />
            <div className="truncate">{capitalize(label)}</div>
          </div>
        ) : (
          <div className={`${sharedClassName}`}>
            <div className="truncate">{capitalize(label)}</div>
          </div>
        )}

      </a>
    </Link>
  );
};

export default Chip;
