import React, { ReactElement } from 'react';
import useLocale from 'hooks/useLocale';
import TimerIcon from '../public/images/icons/timer.svg';

interface Props {
  durationSeconds: number;
}

const computeDurationMinutes = (
  durationSeconds: number,
): number => Math.max(1, Math.round(durationSeconds / 60));

const Timer: React.FC<Props> = ({ durationSeconds }): ReactElement => {
  const { t } = useLocale();

  return (
    <div className="inline-flex text-sm font-semibold text-gray-600 dark:text-dark-top-medium">
      <TimerIcon className="w-5 h-5 fill-current" />
      <span className="ml-1">
        {t('duration_read').replace('{DURATION}', computeDurationMinutes(durationSeconds))}
      </span>
    </div>
  );
};

export default Timer;
