import React, { useState, EffectCallback } from 'react';

export type Theme = 'light' | 'dark'

export default function useTheme(): Theme {
  const isClient = typeof window === 'object';

  const getMql = (): any => (isClient && window && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)'))
  || undefined;

  const getBrowserTheme = (): Theme => {
    const mql = getMql();
    return mql && mql.matches ? 'dark' : 'light';
  };

  const [theme, setTheme] = useState<Theme>(getBrowserTheme());

  React.useEffect((): EffectCallback => {
    if (!isClient) {
      return null;
    }

    function handleThemeUpdate(): void {
      setTheme(getBrowserTheme());
    }

    const mql = getMql();
    if (mql) {
      mql.addListener(handleThemeUpdate);
      return (): void => mql.removeListener(handleThemeUpdate);
    }
    return null;
  }, []);

  return theme;
}
