import React, { ReactElement, useState, EffectCallback } from 'react';
import animateScrollTo from 'animated-scroll-to';
import _ from 'lodash';
import useWindowSize from 'hooks/useWindowSize';
import Previous from '../public/images/icons/left.svg';
import Next from '../public/images/icons/right.svg';

interface Props {
  containerId: string;
  itemClass: string;
  delta: number;
  controlsClass?: string;
}

const HorizontalScroll: React.FC<Props> = (
  {
    children, containerId, itemClass, delta, controlsClass = '',
  },
): ReactElement => {
  const windowSize = useWindowSize();
  const [scrollPosition, setScrollPosition] = useState(-1);
  const [maxPosition, setMaxPosition] = useState(-1);

  function getContainer(): HTMLElement {
    return document.getElementById(containerId);
  }

  function getItems(): HTMLCollectionOf<HTMLElement> {
    const container = getContainer();
    if (!container) {
      return null;
    }
    return container.getElementsByClassName(itemClass) as HTMLCollectionOf<HTMLElement>;
  }

  function hasScrollbar(): boolean {
    const container = getContainer();
    if (!container) {
      return false;
    }
    return container.scrollWidth > container.clientWidth;
  }

  function getScrollPosition(): number {
    const container = getContainer();
    if (!container) {
      return -1;
    }
    return container.scrollLeft;
  }

  function getMaxPosition(): number {
    const container = getContainer();
    if (!container) {
      return -1;
    }
    return container.scrollWidth - container.clientWidth;
  }

  function scrollTo(position: number): void {
    const container = getContainer();
    if (container) {
      animateScrollTo([position, null], { elementToScroll: container });
    }
  }

  function getFirstPartiallyVisible(): HTMLElement {
    const items = getItems();
    if (!items) {
      return null;
    }

    for (let i = 0; i < items.length; i += 1) {
      if (items[i].offsetLeft + items[i].clientWidth > scrollPosition) {
        return items[i];
      }
    }
    return null;
  }

  function getLastPartiallyVisible(): HTMLElement {
    const container = getContainer();
    const items = getItems();

    if (!items) {
      return null;
    }

    for (let i = 0; i < items.length; i += 1) {
      if (items[i].offsetLeft + items[i].clientWidth > container.clientWidth + scrollPosition) {
        return items[i];
      }
    }
    return null;
  }

  function goPrevious(): void {
    const target = getFirstPartiallyVisible();

    if (target) {
      const container = getContainer();
      scrollTo(target.offsetLeft - container.clientWidth + target.clientWidth + delta + delta / 2);
    }
  }

  function goNext(): void {
    const target = getLastPartiallyVisible();
    if (target) {
      scrollTo(target.offsetLeft - delta / 2);
    }
  }

  const Controls: React.FunctionComponent<{}> = (): ReactElement => (
    <div className={`w-full ${controlsClass}`}>
      <div role="button" className={`focus:outline-none cursor-pointer absolute left-0 ${scrollPosition > 0 ? 'show-it' : 'hide-it'} -mt-5 top-1/2 z-10`} onClick={goPrevious} onKeyPress={goPrevious} tabIndex={0}>
        <div className="w-10 h-10 bg-white border border-gray-400 rounded-full shadow-lg opacity-100 dark:bg-dark-light dark:border-dark-medium">
          <div className="flex items-center justify-center w-full h-full">
            <Previous className="w-6 h-6 m-auto text-gray-600 fill-current dark:text-dark-top-medium" />
          </div>
        </div>
      </div>
      <div role="button" className={`focus:outline-none cursor-pointer absolute right-0 ${scrollPosition < maxPosition ? 'show-it' : 'hide-it'} -mt-5 top-1/2 z-10`} onClick={goNext} onKeyPress={goNext} tabIndex={0}>
        <div className="w-10 h-10 bg-white border border-gray-400 rounded-full shadow-lg opacity-100 dark:bg-dark-light dark:border-dark-medium">
          <div className="flex items-center justify-center w-full h-full">
            <Next className="w-6 h-6 m-auto text-gray-600 fill-current dark:text-dark-top-medium" />
          </div>
        </div>
      </div>
    </div>
  );

  React.useEffect(() => {
    if (!hasScrollbar()) {
      setScrollPosition(-1);
      setMaxPosition(-1);
    } else {
      setScrollPosition(getScrollPosition());
      setMaxPosition(getMaxPosition());
    }
  }, [windowSize]);

  React.useEffect((): EffectCallback => {
    function handleScroll(): void {
      setScrollPosition(getScrollPosition());
      setMaxPosition(getMaxPosition());
    }

    const container = document.getElementById(containerId);
    if (container) {
      container.addEventListener('scroll', _.throttle(handleScroll, 100));
    }
    return (): void => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <div className="relative h-full">
      <Controls />
      {children}
    </div>
  );
};

export default HorizontalScroll;
