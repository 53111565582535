import React, { ReactElement } from 'react';
import useLocale from 'hooks/useLocale';

interface Props {
  title: string;
  className?: string;
  margins?: string;
  icon?: ReactElement;
  headerLevel?: number;
}

const SectionHeader: React.FC<Props> = ({ title, icon, headerLevel }): ReactElement => {
  const { t } = useLocale();
  return (
    React.createElement(
      headerLevel ? `h${headerLevel}` : 'div',
      { className: 'px-3 pt-1 pb-2 text-sm font-bold tracking-wide text-gray-600 uppercase dark:text-dark-top-medium' },
      (icon ? (
        <div className="flex items-center">
          <span>{t(title)}</span>
          <span>{icon}</span>
        </div>
      ) : t(title)),
    ));
};

const Section: React.FC<Props> = ({
  children, title, className = '', margins = 'mt-3 mb-5', icon = null, headerLevel = 0,
}): ReactElement => (
  <div className={`${margins} ${className}`}>
    {title ? <SectionHeader title={title} icon={icon} headerLevel={headerLevel} /> : null}
    {children}
  </div>
);

export default Section;
