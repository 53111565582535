import React from 'react';
import Img from 'react-image';
import { Image as ArticleImageType, Article } from 'core/types';
import ReactTimeAgo from 'react-time-ago';
import { useRouter } from 'next/router';
import useTheme from 'hooks/useTheme';
import Link, { LinkProps } from 'next/link';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  EmailIcon,
  EmailShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
} from 'react-share';
import useLocale from 'hooks/useLocale';

import {
  get,
  isUrl,
  capitalizeFirstLetter,
  truncate,
  getFaviconURL,
  getArticleURLId,
  getLinkProps,
  deduplicate,
  removePrefix,
  updateParameters,
  getAdsProvider,
} from 'core/utils';
import { DISABLE_ARTICLE_IMAGES_COUNTRIES, SHORT_ARTICLE_HOSTNAMES, ENABLE_ADS } from 'core/configs';
import Image from 'components/Image';
import Network from '../public/images/icons/network.svg';
import MoneyIcon from '../public/images/icons/currency-dollar.svg';
import Photo from '../public/images/icons/photo.svg';
import Tag from './Tag';
import Chip from './Chip';
import TopicsBanner from './TopicsBanner';
import HorizontalSpacer from './HorizontalSpacer';
import ReadabilityLevel from './ReadabilityLevel';
import Timer from './Timer';
import AdSense from './AdSense';
import { SimplePublift, ARTICLE_IN_CONTENT_1, ARTICLE_IN_CONTENT_2 } from './Publift';

interface Props {
  article: Article;
}

const computeImageSourceKey = (source: string): string => source.replace(/[0-9]{2,}/g, '0');

const inferDimensions = (image: ArticleImageType): ArticleImageType => {
  const { url } = image;
  let { width, height } = image;
  const urlObject = new URL(url);
  if (!width) {
    width = Number(urlObject.searchParams.get('width') || urlObject.searchParams.get('w')) || 0;
  }
  if (!height) {
    height = Number(urlObject.searchParams.get('height') || urlObject.searchParams.get('h')) || 0;
  }
  return { url, width, height };
};

const pickImageSource = (images: ArticleImageType[], width: number, height: number): string => {
  if (!images) {
    return null;
  }

  const candidates = images.filter((image) => image.url && isUrl(image.url));
  if (candidates.length === 0) {
    return null;
  }
  if (candidates.length === 1) {
    return candidates[0].url;
  }

  const firstKey = computeImageSourceKey(candidates[0].url);
  const finalCandidates = candidates.filter(
    (image) => computeImageSourceKey(image.url) === firstKey,
  ).map(inferDimensions);

  for (const candidate of finalCandidates) {
    if (candidate.width >= width && candidate.height >= height) {
      return candidate.url;
    }
  }

  return candidates[0].url;
};

const ArticleImage: React.FunctionComponent<
{
  article: Article;
  imageClasses?: string;
  defaultImageClasses?: string;
  width?: number;
  height?: number;
}> = (
  {
    article, imageClasses, defaultImageClasses, width, height,
  },
) => {
  const articleCountry = get(article, 'labels.country', '');
  if (DISABLE_ARTICLE_IMAGES_COUNTRIES.has(articleCountry)) {
    return null;
  }

  if (article.images && article.images[0].url && isUrl(article.images[0].url)) {
    const defaultPhoto = <Photo className={`${defaultImageClasses || imageClasses} text-gray-200 fill-current dark:text-dark-light`} />;

    const imageSource = pickImageSource(article.images, width || 0, height || 0);
    if (!imageSource) {
      return null;
    }

    return (
      <Image
        source={imageSource}
        imageClasses={`${imageClasses} relative`}
        loader={defaultPhoto}
        unloader={null}
        transform={{
          fit: 'cover', we: '', width, height,
        }}
        attributes={{ alt: article.title }}
        lazyLoadOffset={600}
        minWidth={100}
        minHeight={100}
      />
    );
  }

  return null;
};

const FaviconImage: React.FunctionComponent<{hostname: string}> = (
  { hostname },
) => {
  const imageClasses = 'inline-block w-4 h-4';
  const defaultPhoto = <Network className={`${imageClasses} text-gray-200 fill-current dark:text-dark-light`} />;
  return (
    <Img
      src={getFaviconURL(hostname)}
      className={imageClasses}
      loader={defaultPhoto}
      unloader={defaultPhoto}
      alt={hostname}
    />
  );
};

const Breadcrumb: React.FunctionComponent<Props> = ({ article }) => {
  if (!article.breadcrumb) {
    return null;
  }
  return (
    <div className="px-3 mt-5 text-sm font-bold tracking-wide text-gray-600 uppercase dark:text-dark-top-medium">
      {article.breadcrumb.join(' / ')}

    </div>
  );
};

const Authors: React.FunctionComponent<Props> = ({ article }) => {
  if (!article.authors) {
    return null;
  }
  return (
    <div className="px-3 mt-4 text-base font-bold text-gray-700 dark:text-dark-top-light ">
      {article.authors.map((item, index) => <span key={String(index)}>{ (index ? ', ' : '') + item.name }</span>)}
    </div>
  );
};

const Keywords: React.FunctionComponent<Props> = ({ article }) => {
  const router = useRouter();

  if (!article.keywords) {
    return null;
  }

  let { keywords } = article;
  if (keywords.length === 1) { keywords = keywords[0].split(','); }

  return (
    <div className="px-3 mt-5">
      <div className="flex flex-wrap -mx-1">
        {deduplicate(keywords).map((item, index) => (
          <div className="flex-shrink-0 px-1">
            <Tag key={String(index)} label={item} linkProps={getLinkProps(router.query, { query: item }, '/search')} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Paywall: React.FunctionComponent<Props> = ({ article }) => {
  if (article.is_accessible_for_free === false) {
    return (
      <div className="inline-flex">
        <MoneyIcon className="w-5 h-5 text-yellow-500 fill-current" />
      </div>
    );
  }
  return null;
};

const SocialShare: React.FunctionComponent<Props> = ({ article }) => {
  const router = useRouter();
  const url = `${process.env.FRONTEND_API_HOST}${router.asPath}`;

  return (
    <div className="p-2 text-center bg-gray-100 border-t border-gray-400 dark:bg-dark-light dark:border-dark-light">
      <span className="mr-2">
        <FacebookShareButton
          url={updateParameters(url, { source: 'share_facebook' })}
          quote={article.title}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </span>
      <span className="mr-2">
        <TwitterShareButton
          url={updateParameters(url, { source: 'share_twitter' })}
          title={article.title}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </span>
      <span className="mr-2">
        <LinkedinShareButton
          url={updateParameters(url, { source: 'share_linkedin' })}
          title={article.title}
          summary={article.description}
          source="knowledia"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </span>
      <span className="mr-2">
        <RedditShareButton
          url={updateParameters(url, { source: 'share_reddit' })}
          title={article.title}
        >
          <RedditIcon size={32} round />
        </RedditShareButton>
      </span>
      <span className="mr-2">
        <WhatsappShareButton
          url={updateParameters(url, { source: 'share_whatsapp' })}
          title={article.title}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </span>
      <span className="mr-2">
        <EmailShareButton
          url={updateParameters(url, { source: 'share_email' })}
          subject={article.title}
          body={article.description}
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </span>
      <span className="mr-2">
        <PocketShareButton
          url={updateParameters(url, { source: 'share_pocket' })}
          title={article.title}
        >
          <PocketIcon size={32} round />
        </PocketShareButton>
      </span>
    </div>
  );
};

const getFactCheckingLinkProps = (): LinkProps => {
  const router = useRouter();
  return getLinkProps(router.query, { query: '', isFactChecking: '' }, '/search');
};

const getCategoryLinkProps = (category: string): LinkProps => {
  const router = useRouter();
  return getLinkProps(router.query, { query: '', category }, '/search');
};

const Categories: React.FunctionComponent<Props> = ({ article }) => {
  const { t } = useLocale();

  return (article.is_fact_checking || (article.labels && article.labels.category) ? (
    <div className="px-3 py-2">
      {article.is_fact_checking
        ? (
          <Chip
            label={t('fact-checking')}
            linkProps={getFactCheckingLinkProps()}
            className="mr-2"
          />
        ) : null}
      {article.labels.category
        ? (
          <Chip
            label={t(article.labels.category)}
            linkProps={getCategoryLinkProps(article.labels.category)}
          />
        ) : null}
    </div>
  ) : null);
};

export const ArticleCardLoading: React.FunctionComponent<{className?: string}> = (
  { className = '' },
) => (
  <div className={`w-full overflow-hidden bg-white dark:bg-dark-medium border border-gray-400 dark:border-dark-light element ${className}`}>
    <div className="flex flex-col h-full">
      <Photo className="w-full h-64 overflow-hidden text-gray-200 fill-current dark:text-dark-light" />
      <div className="mx-6 my-2 bg-gray-200 rounded-lg dark:bg-dark-light w-12/12">&nbsp;</div>
      <div className="mx-6 my-2 bg-gray-200 rounded-lg dark:bg-dark-light w-12/12">&nbsp;</div>
      <div className="w-48 mx-6 mt-2 mb-4 bg-gray-200 rounded-lg dark:bg-dark-light">&nbsp;</div>
    </div>
  </div>
);

export const CompactArticleCardLoading: React.FunctionComponent<{className?: string}> = (
  { className = '' },
) => (
  <div className={`w-full h-32 overflow-hidden bg-white dark:bg-dark-medium border border-gray-400 dark:border-dark-light element ${className}`}>
    <div className="flex w-full h-full">
      <div className="flex-shrink-0 w-32 h-32">
        <div className="flex items-center justify-center h-full">
          <Photo className="w-24 h-24 overflow-hidden text-gray-200 fill-current dark:text-dark-light" />
        </div>
      </div>
      <div className="flex flex-col flex-grow px-3">
        <div className="w-full my-2 bg-gray-200 rounded-lg dark:bg-dark-light">&nbsp;</div>
        <div className="w-full my-2 bg-gray-200 rounded-lg dark:bg-dark-light">&nbsp;</div>
        <div className="w-48 px-3 my-2 bg-gray-200 rounded-lg dark:bg-dark-light">&nbsp;</div>
      </div>
    </div>
  </div>
);

export const ArticleCard: React.FunctionComponent<Props> = ({ article }) => {
  const { interfaceLanguage } = useLocale();
  const router = useRouter();

  const linkProps = getLinkProps(router.query, { id: getArticleURLId(article) }, '/articles/[id]');

  return (
    <div className="w-full overflow-hidden bg-white border border-gray-400 dark:bg-dark-medium dark:border-dark-light element">
      <Link href={linkProps.href} as={linkProps.as}>
        <a className="flex flex-col h-full">
          <div>
            <ArticleImage article={article} imageClasses="object-cover w-full h-64 overflow-hidden" height={256} />
          </div>
          <div className="px-3 pt-4 text-lg font-bold leading-snug tracking-tight dont-break dark:text-dark-top-light">
            {truncate(article.title, 120, true)}
          </div>
          <div className="px-3 pt-1 pb-2 text-sm font-semibold tracking-wide text-gray-600 dark:text-dark-top-medium">
            <ReactTimeAgo date={new Date(article.creation_date)} locale={interfaceLanguage} />
          </div>
          <div className="flex justify-start flex-1 px-3 text-base text-gray-700 dark:text-dark-top-medium">
            {truncate(article.description, 420, true)}
          </div>
          <Categories article={article} />
          <div className="flex items-center px-3 pt-2 pb-4">
            <FaviconImage hostname={article.hostname} />
            <div className="inline-block px-2 text-sm tracking-wider text-gray-600 truncate dark:text-dark-top-medium">{article.hostname}</div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export const CompactArticleCard: React.FunctionComponent<Props> = ({ article }) => {
  const { interfaceLanguage } = useLocale();
  const router = useRouter();

  const linkProps = getLinkProps(router.query, { id: getArticleURLId(article) }, '/articles/[id]');

  return (
    <div className="w-full h-32 overflow-hidden bg-white border border-gray-400 dark:border-dark-light dark:bg-dark-medium element">
      <Link href={linkProps.href} as={linkProps.as}>
        <a className="flex h-full">
          <div className="flex-shrink-0">
            <ArticleImage article={article} imageClasses="object-cover h-32 w-32 overflow-hidden" defaultImageClasses="object-cover h-24 w-24 m-4 overflow-hidden" width={128} height={128} />
          </div>
          <div className="flex flex-col justify-between">
            <div className="px-3 pt-2 text-sm font-bold leading-snug tracking-tight sm:text-base dont-break dark:text-dark-top-light">
              {truncate(article.title, 100, true)}
              <div className="pt-1 pb-1 text-xs font-normal tracking-wide text-gray-600 dark:text-dark-top-medium">
                <ReactTimeAgo date={new Date(article.creation_date)} locale={interfaceLanguage} />
              </div>
            </div>
            <div className="flex items-center px-3 pb-2">
              <FaviconImage hostname={article.hostname} />
              <div className="inline-block px-2 text-xs tracking-wider text-gray-600 truncate dark:text-dark-top-medium">{article.hostname}</div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export const DetailedArticleCard: React.FunctionComponent<Props> = ({ article }) => {
  const { t, interfaceLanguage } = useLocale();
  const router = useRouter();
  const adsProvider = getAdsProvider(router.query);
  const theme = useTheme();
  const body = removePrefix(article.body, article.description ? article.description.replace(/\.\.\.$/, '') : article.description);
  let maxBodyLength = 2000;
  if (SHORT_ARTICLE_HOSTNAMES.has(article.hostname)) {
    maxBodyLength = 300;
  }
  const bodySize = body ? Math.min(Math.round(body.length / 3), maxBodyLength) : 0;

  return (
    <div className="w-full overflow-hidden bg-white border border-gray-400 dark:bg-dark-medium dark:border-dark-light element">
      <div className="flex flex-col h-full">
        <div>
          <ArticleImage article={article} imageClasses="object-cover w-full h-64 lg:h-96 overflow-hidden" height={384} />
        </div>
        <TopicsBanner
          listId="topicsList"
          entities={article.entities}
          title=""
          sectionClassName="bg-gray-200 py-3 border-b border-gray-400 dark:border-dark-light dark:bg-dark-deep"
          sectionMargins="mt-0 mb-0"
        />

        <Breadcrumb article={article} />
        <h1 className="px-3 mt-3 text-xl font-bold leading-snug tracking-tight dont-break dark:text-dark-top-light">
          {article.title}
        </h1>
        <div className="px-3 text-sm font-semibold tracking-wide text-gray-600 dark:text-dark-top-medium">
          <ReactTimeAgo date={new Date(article.creation_date)} locale={interfaceLanguage} />
        </div>
        <div className="flex items-center px-3 mt-1">
          <FaviconImage hostname={article.hostname} />
          <div className="inline-block px-2 text-sm tracking-wider text-gray-600 truncate dark:text-dark-top-medium">{article.hostname}</div>
        </div>
        <div className="flex items-center px-3 mt-2">
          <Timer durationSeconds={article.read_time_seconds} />
          <HorizontalSpacer size={2} />
          <ReadabilityLevel scores={article.readability} />
          <HorizontalSpacer size={2} />
          <Paywall article={article} />
        </div>
        <Categories article={article} />
        <Keywords article={article} />
        <div className="flex justify-start flex-1 px-3 mt-5 text-base font-semibold text-gray-700 dark:text-dark-top-medium dont-break">
          {article.description}
        </div>
        {ENABLE_ADS && adsProvider === 'adsense' ? (
          <AdSense
            className="w-full m-auto mt-4"
            style={{ display: 'block', textAlign: 'center' }}
            layout="in-article"
            format="fluid"
            client="ca-pub-0052870555576826"
            slot={theme === 'dark' ? '8348967659' : '9582158273'}
            name="in_article_top"
          />
        ) : null}

        {ENABLE_ADS && adsProvider === 'publift' ? (
          <SimplePublift fuseId={ARTICLE_IN_CONTENT_1} />
        ) : null}

        {body ? (
          <div className="px-3 mt-4 text-base text-gray-700 dark:text-dark-top-medium dont-break">
            {truncate(body, bodySize, true).split('\n').map((item, index) => (
              <span key={String(index)}>
                {item}
                <br />
              </span>
            ))}
          </div>
        )
          : null}
        {
          ENABLE_ADS && adsProvider === 'adsense' && body && bodySize > 1500
            ? (
              <AdSense
                className="w-full m-auto mt-4"
                style={{ display: 'block', textAlign: 'center' }}
                layout="in-article"
                format="fluid"
                client="ca-pub-0052870555576826"
                slot={theme === 'dark' ? '8348967659' : '9582158273'}
                name="in_article_bottom"
              />
            )
            : null
        }

        {ENABLE_ADS && adsProvider === 'publift' ? (
          <SimplePublift fuseId={ARTICLE_IN_CONTENT_2} />
        ) : null}

        <Authors article={article} />
        <a href={article.url} className="my-5 text-center" rel="noopener noreferrer" target="_blank">
          <div className="inline-block px-3 py-1 text-base font-semibold leading-loose text-white bg-indigo-600 hover:bg-indigo-700 dark:text-white dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:hover:text-white element">
            {capitalizeFirstLetter(t('read full article'))}
          </div>
        </a>
        <SocialShare article={article} />
      </div>
    </div>
  );
};
