import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { ENABLE_ADS } from 'core/configs';
import { getAdsProvider } from 'core/utils';

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

interface Props {
  className?: string;
  style?: object;
  client: string;
  slot: string;
  layout?: string;
  format: string;
  name: string;
  fullWidthResponsive?: string;
}

const AdSense: React.FC<Props> = ({
  className = '', style = { display: 'block' }, client, slot, layout = '', format = 'auto', name, fullWidthResponsive = null,
}): ReactElement => {
  const router = useRouter();
  const adsProvider = getAdsProvider(router.query);

  if (!ENABLE_ADS || adsProvider !== 'adsense') {
    return null;
  }

  React.useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
  }, [router.asPath]);

  return (
    <ins
      key={`${router.asPath}_${name}`}
      className={`${className} adsbygoogle`}
      style={style}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-layout={layout}
      data-ad-format={format}
      data-adtest={process.env.NODE_ENV !== 'production' ? 'on' : 'off'}
      data-full-width-responsive={fullWidthResponsive}
    />
  );
};

export default AdSense;
