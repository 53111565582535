import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { LinkProps } from 'next/link';
import useLocale from 'hooks/useLocale';
import {
  getLinkProps,
} from 'core/utils';
import { Entity } from 'core/types';
import Section from './Section';

import Chip, { ChipLoading } from './Chip';
import HorizontalScroll from './HorizontalScroll';

interface Props {
  listId: string;
  entities: Entity[];
  title: string;
  hiddenTopics?: string[];
  sectionClassName?: string;
  sectionMargins?: string;
}

export const TopicsBannerLoading: React.FunctionComponent<{title: string}> = (
  { title },
): ReactElement => (
  <Section title={title} className="flash-it">
    <div className="flex px-3 overflow-auto hide-scrollbar">
      {[...Array(10)].map((item) => <ChipLoading key={String(item)} />)}
    </div>
  </Section>
);

const TopicsBanner: React.FunctionComponent<Props> = (
  {
    listId, entities, title, hiddenTopics, sectionClassName, sectionMargins,
  },
): ReactElement => {
  const router = useRouter();
  const { translateLabel } = useLocale();

  const getEntityLinkProps = (entityId: string): LinkProps => {
    if (router.pathname.indexOf('/topics/[id]') !== -1) {
      return getLinkProps(router.query, { id: entityId }, '/topics/[id]');
    }

    return getLinkProps(router.query, { query: '', topic: entityId }, '/search');
  };

  if (!entities || entities.length === 0) {
    return null;
  }

  return (
    <Section title={title} className={sectionClassName} margins={sectionMargins}>
      <HorizontalScroll containerId={listId} itemClass="topic" delta={12} controlsClass="hidden sm:block">
        <div id={listId} className="flex px-3 overflow-auto hide-scrollbar">
          {entities.map((entity) => (
            hiddenTopics && hiddenTopics.includes(entity.id) ? null
              : (
                <Chip
                  key={entity.id}
                  entityId={entity.id}
                  label={translateLabel(entity.labels)}
                  className="w-full h-full mr-1"
                  linkClassName="mr-1"
                  linkProps={getEntityLinkProps(entity.id)}
                />
              )
          ))}
        </div>
      </HorizontalScroll>
    </Section>
  );
};

export default TopicsBanner;
