import React from 'react';

import useLocale from 'hooks/useLocale';

interface Props {
  error: Error;
}

const ErrorAlert: React.FunctionComponent<Props> = ({ error }) => {
  const { t } = useLocale();

  return (
    <div role="alert" className="m-4">
      <div className="px-3 py-2 font-bold text-white uppercase bg-red-500 rounded-t">
        {t('error')}
      </div>
      <div className="px-3 py-3 text-red-700 bg-red-100 border border-t-0 border-red-400 rounded-b">
        <p>{error.message}</p>
      </div>
    </div>
  );
};

export default ErrorAlert;
