import React, { ReactElement } from 'react';
import Link, { LinkProps } from 'next/link';

interface Props {
  label: string;
  linkProps: LinkProps;
}

const Tag: React.FunctionComponent<Props> = (
  {
    label, linkProps,
  },
): ReactElement => (
  <Link href={linkProps.href} as={linkProps.as}>
    <a>
      <span className="inline-block px-2 text-xs font-semibold tracking-wide text-white uppercase truncate bg-indigo-600 rounded-full hover:text-indigo-500 hover:bg-indigo-100 dont-break dark:bg-indigo-300 dark:text-indigo-900">
        {label}
      </span>
    </a>
  </Link>
);

export default Tag;
